import React from "react"
import { graphql } from "gatsby"
import BlogLayout from "../components/blog-layout"
import PostCard from "../components/blog/post-card"
import CategoriesNav from "../components/blog/categories-nav"

class Blog extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.contentfulPosts.posts

    return (
      <BlogLayout title={siteTitle}>
        <section className="min-h-96">
          <div className="container mx-auto px-6 py-10">
            <CategoriesNav categories={data.categories.nodes} />
            <div className="flex flex-col space-y-8">
              {posts.map(post => {
                return <PostCard post={post} key={post.id} />
              })}
            </div>
          </div>
        </section>
      </BlogLayout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    categories: allContentfulPostCategory {
      nodes {
        name
        slug
      }
    }
    contentfulPosts: allContentfulBlogPost(
      limit: 200
      sort: { order: DESC, fields: [postDate] }
    ) {
      posts: nodes {
        id
        title
        slug
        tags
        youTubeLink
        videoEmbedCode
        postDate
        category {
          name
          slug
        }
        introContent {
          excerpt: introContent
        }
        content {
          text: childMarkdownRemark {
            timeToRead
          }
        }
        cover {
          id
          fluid {
            tracedSVG
            srcWebp
            srcSetWebp
            srcSet
            src
            sizes
            aspectRatio
          }
        }
      }
    }
  }
`
